/* Styles pour la barre de navigation */
.barnav {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 30vh; /* Hauteur de la barre de navigation */
  z-index: 1; /* Ajoutez cette ligne pour placer la barre de navigation au-dessus du canvas */
}


/* Styles pour les liens de navigation */
.nav-links {
  position: absolute;
  top: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 100px 0;
  padding: 0;
  z-index: 1;
  width: 100%;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li:first-child {
  margin-right: 10px;
}

.nav-links li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 10px;
  color: black;
  font-size: 24px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}

/* Styles pour le fond du canvas */
.background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFA500;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%); /* Créer une forme polygonale pour la diagonale */
}

/* Styles pour le logo */
.logo {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 60%;
  max-height: 60%;
}
