body {
  background-color: black;
}

.container {
    padding: 3%; /* Ajouter des marges de chaque côté */
}

.h3 {
  color: #FF6347; /* couleur orange */
  text-align: center;
  font-size: 24px; /* taille de police agrandie */
  font-weight: bold; /* texte en gras */
  text-transform: uppercase; /* texte en majuscules */
}