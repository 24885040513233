.bodycontact {
  color: #333; /* Couleur du texte */
  font-family: Arial, sans-serif; /* Police de caractère */
}

.h1contact {
  color: #333; /* Couleur du titre */
  text-align: center; /* Centrage du titre */
  margin-top: 20px; /* Marge supérieure */
}

.contact-form {
  background-color: #fff; /* Couleur de fond du formulaire */
  border-radius: 10px; /* Bord arrondi */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre douce */
  padding: 20px; /* Espacement intérieur */
  max-width: 500px; /* Largeur maximale du formulaire */
  margin: 20px auto; /* Marge extérieure */
}

.form-group {
  margin-bottom: 20px; /* Marge inférieure pour les groupes de formulaire */
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%; /* Largeur du champ de saisie */
  padding: 10px; /* Espacement intérieur */
  border: 1px solid #ccc; /* Bordure */
  border-radius: 5px; /* Bord arrondi */
  font-size: 16px; /* Taille de la police */
}

textarea {
  height: 150px; /* Hauteur du champ de texte */
}

button {
  background-color: #333; /* Couleur de fond du bouton */
  color: #fff; /* Couleur du texte du bouton */
  border: none; /* Supprimer la bordure */
  padding: 10px 20px; /* Espacement intérieur */
  border-radius: 5px; /* Bord arrondi */
  cursor: pointer; /* Curseur pointeur */
  font-size: 16px; /* Taille de la police */
  width: 100%; /* Largeur du bouton */
  transition: background-color 0.3s; /* Transition douce pour l'effet hover */
}

button:hover {
  background-color: #555; /* Couleur de fond du bouton au survol */
}
