/* Title1.css */
.title-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Ajuste la hauteur selon tes besoins */
}

.design-title {
  position: absolute;
  transition: transform 0.5s ease, left 0.5s ease, opacity 0.5s ease; /* Animation de transition pour le mouvement, le décalage latéral et l'opacité */
  font-size: 66px; /* Taille de police par défaut */
}

/* Design qui monte */
.design-title:nth-child(1) {
  color: #707070; /* Couleur */
  opacity: 0.2; /* Opacité maximale */
}

/* Design qui reste en place */
.design-title:nth-child(2) {
  color: #FFA500; /* Couleur */
  opacity: 0.7; /* Opacité réduite */
}

/* Design qui descend */
.design-title:nth-child(3) {
  color: #CB8300; /* Couleur */
  opacity: 0.2; /* Opacité minimale */
}

/* Media query pour les petits écrans */
@media only screen and (max-width: 800px) {
  .text-container {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: white;
  margin-bottom: 20px; 
  padding: 20px;
  text-align: justify;
  line-height: 1.5;
  hyphens: auto; /* Permet au navigateur de gérer les coupures de mots automatiques */
}


  .design-title {
    margin-bottom: 5%;
    font-size: 33px; /* Taille de police réduite pour les petits écrans */
  }
}

.design-title {
  transition: transform 0.3s ease; /* Ajouter une transition pour lisser les changements de position */
}

.design-title.duplicate {
  opacity: 0.2; /* Réduire l'opacité pour rendre les éléments dupliqués plus discrets */
}
