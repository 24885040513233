.bodyteam {
  color: #333; /* Couleur du texte */
  font-family: Arial, sans-serif; /* Police de caractère */
}

.h1team {
  color: #333; /* Couleur du titre */
  text-align: center; /* Centrage du titre */
  margin-top: 20px; /* Marge supérieure */
}

.team-member-card {
  background-color: #fff; /* Couleur de fond de la carte */
  border-radius: 10px; /* Bord arrondi */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre douce */
  padding: 20px; /* Espacement intérieur */
  text-align: center; /* Centrage du contenu */
  margin: 20px auto; /* Marge extérieure */
  max-width: 300px; /* Largeur maximale de la carte */
}

.team-member-image {
  background-color: #ccc; /* Couleur de fond de l'image */
  width: 150px; /* Largeur de l'image */
  height: 150px; /* Hauteur de l'image */
  border-radius: 50%; /* Forme circulaire */
  margin: 0 auto 20px; /* Marge inférieure */
}

.team-member-profile {
  background-color: #fff; /* Couleur de fond de la fiche */
  border-radius: 10px; /* Bord arrondi */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre douce */
  padding: 20px; /* Espacement intérieur */
  margin: 20px auto; /* Marge extérieure */
  max-width: 500px; /* Largeur maximale de la fiche */
}

.team-member-profile h3 {
  color: #333; /* Couleur du titre */
  text-align: center; /* Centrage du titre */
  margin-bottom: 20px; /* Marge inférieure */
}

.team-member-profile p {
  color: #666; /* Couleur du texte */
  line-height: 1.6; /* Hauteur de ligne */
  margin-bottom: 10px; /* Marge inférieure */
}
