/* Styles pour le conteneur du formulaire */
.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  font-family: Arial, sans-serif;
}

.contact-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #ffa500;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffa500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px; /* Ajout de padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333;
  color: white;
  font-size: 16px;
  box-sizing: border-box; /* S'assurer que le padding n'affecte pas la taille totale */
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #ffa500;
}

textarea {
  resize: vertical;
}

button {
  width: 100%;
  padding: 10px 20px;
  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e69500;
}

.success-message {
  text-align: center;
  color: #4caf50;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #f44336;
  margin-bottom: 15px;
}
