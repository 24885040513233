.text-container {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: white;
  margin-bottom: 20px; 
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: justify;
  line-height: 1.5; 
}

.text-container2 {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: white;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: justify;
  line-height: 1.5;
  display: flex; /* Pour activer le modèle flexbox */
  justify-content: center; /* Pour centrer le contenu horizontalement */
  align-items: center; /* Pour centrer le contenu verticalement */
}


.presentation-container {
  margin: 15%;
  display: flex;
  flex-wrap: wrap;
}

.top-left .text-container {
  max-width: 50%;
  margin-right: auto; /* Ajout de cette ligne pour aligner à gauche */
}

.bottom-right .text-container {
  max-width: 50%;
  margin-left: auto; /* Ajout de cette ligne pour aligner à droite */
}


/* Règles CSS pour les appareils mobiles */
@media only screen and (max-width: 800px) {
  .text-container,
  .text-container2 {
    font-size: 18px; /* Taille de la police réduite */
    text-align: left; /* Centrer le texte */
    margin: 0; /* Réinitialiser toutes les marges */
    padding: 10px; /* Ajouter un peu de rembourrage */
  }

  .presentation-container {
    margin: 10px; /* Ajuster la marge */
  }

  .fin {
    color: white;
    text-align: center; /* Centrer le texte */
    font-size: 18px;
    font-family: Arial, sans-serif;
  font-weight: bold;
  }

  .top-left .text-container,
  .bottom-right .text-container {
    max-width: 100%; /* Largeur maximale à 100% */
    margin: 0 auto 20px; /* Centrer verticalement */
  


    

  }
}
