/* Styles généraux */
.text-arial-bold {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

/* Mise en page en colonnes */
.container {
  display: flex;
  justify-content: center; /* Aligner les éléments au centre horizontalement */
}

.column1 {
  flex: 1; /* Utilisez l'espace disponible pour chaque colonne */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligner les éléments complètement à gauche */
  text-align: left;
  margin-top: 70px; /* Ajoutez la marge supérieure uniquement à la colonne 1 */
}

.column-container {
  flex: 2; /* Utilisez 2 fois plus d'espace que les autres colonnes */
  display: flex;
  justify-content: space-between; /* Espacement égal entre les colonnes */
}

.column2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  align-items: center; /* Centrer horizontalement */
  text-align: center;
}

.column3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligner les éléments complètement à droite */
  text-align: right;
}

/* Styles spécifiques */
.newsletter-button {
  text-align: center;
}

.newsletter-button h2 {
  font-size: 24px;
  color: white;
  margin-bottom: 10px; /* Espacement entre le titre et le bouton */
}

.newsletter-btn {
  padding: 10px 20px;
  background-color: #FFA500; /* Utilisez la même couleur que la barre de navigation */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transition de couleur de fond */
}

.newsletter-btn:hover {
  background-color: #e69500; /* Couleur légèrement plus foncée au survol */
}

.column1 p,
.column2 p,
.column3 p {
  margin: 5px 0; /* Réduire l'espace entre chaque paragraphe */
}

.logoalternatif {
  display: block;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 60%;
  max-width: 40%; /* Assure que le logo ne dépasse pas la moitié de la largeur de la colonne */
  height: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Valeur de z-index élevée pour que la modal soit au premier plan */
}


.modal {
  background: rgb(12, 12, 12);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  max-width: 400px; /* Limiter la largeur de la modal */
  width: 100%;
}

.modal input {
  margin: 10px 0;
  padding: 10px;
  width: calc(100% - 20px);
  box-sizing: border-box; /* S'assurer que l'input reste dans la modal */
}

.subscribe-btn {
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin: 10px 5px;
  padding: 10px 20px;
  background-color: #FFA500; /* Utilisez la même couleur que la barre de navigation */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transition de couleur de fond */
}

.subscribe-btn:hover {
  background-color: #e69500; /* Couleur légèrement plus foncée au survol */
}

.close-btn {
  position: absolute;
  top: 10px;
  left: 180px; /* Décalage sur la droite */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Couleur de la croix */
  font-weight: bold; /* Ajouter une police en gras pour une meilleure visibilité */
}

.close-btn:hover {
  background: none; /* Supprimer la couleur de fond au survol */
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li a {
  text-decoration: none;
  color: #ffffff; /* Couleur du texte par défaut */
  transition: color 0.3s ease; /* Animation de transition de couleur */
}

ul li a:hover {
  color: #e69500; /* Couleur du texte au survol */
}

/* Media query pour les petits écrans */
@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column; /* Empiler les colonnes les unes sur les autres */
    align-items: center; /* Centrer les éléments horizontalement */
  }
  .design-services {
    margin-left: -10px; /* Centrer le texte horizontalement */
  }
  
  .column1,
  .column2,
  .column3 {
    margin-top: 20px; /* Réduire la marge supérieure pour chaque colonne */
  }
}

